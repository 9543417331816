import { gql } from '@apollo/client'

const addPromotion = gql`
  mutation AddPromotion(
    $file: Upload!
    $promotionName: String!
    $platform: String!
  ) {
    addPromotion(
      file: $file
      promotionName: $promotionName
      platform: $platform
    ) {
      id
      promotionName
    }
  }
`

export const PROMOTION_MUTATIONS = {
  UPLOAD_CODES: addPromotion,
}
