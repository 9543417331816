/* eslint-disable no-unused-vars */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React from 'react'
import {
  Container,
  Heading,
  Divider,
  Grid,
  GridItem,
  Box,
  HStack,
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
  Flex,
} from '@chakra-ui/react'
import { useMutation } from '@apollo/client'
import PromotionCreateForm from '../../components/promotion/component/PromotionCreateForm'
import { PROMOTION_MUTATIONS } from '../../components/promotion/gql/promotion.mutations'

const Promotion = () => {
  const [addPromotion, { loading: isSubmitting }] = useMutation(
    PROMOTION_MUTATIONS.UPLOAD_CODES,
    {
      onCompleted: (data) => {
        console.log(data)
      },
      onError: (error) => {
        console.log(error)
      },
    }
  )

  const handleSubmit = (data: any) => {
    const { promotionName, platform, csv } = data
    addPromotion({ variables: { promotionName, platform, file: csv } })
  }

  return (
    <Grid templateColumns="repeat(5, 1fr)">
      <GridItem
        colSpan={6}
        bgColor="#1C1C1D"
        marginTop={3}
        marginRight={3}
        marginBottom={3}
        marginLeft={0}
        padding={5}
        borderRadius={8}
      >
        <Container width="100%" maxW="85%">
          <Container width="100%" m="1rem auto 0rem 0">
            <Heading mb={2} as="h1" size="lg" color="white">
              Promotion
            </Heading>
          </Container>
          <Box width="100%" marginBottom="4rem">
            <Divider />
          </Box>
        </Container>
        <Container>
          <HStack>
            <Box width="50%">
              <Tabs align="end" variant="unstyled">
                <TabList>
                  <Tab _selected={{ color: 'white', bg: 'fitgmrBlue.400' }}>
                    Create
                  </Tab>
                  <Tab
                    _selected={{ color: 'white', bg: 'fitgmrRed.400' }}
                    isDisabled
                  >
                    Update
                  </Tab>
                  <Tab
                    _selected={{ color: 'white', bg: 'fitgmrOrange.400' }}
                    isDisabled
                  >
                    View
                  </Tab>
                </TabList>
                <TabPanels>
                  <TabPanel>
                    <PromotionCreateForm
                      onSubmit={handleSubmit}
                      isSubmitting={isSubmitting}
                    />
                  </TabPanel>
                </TabPanels>
              </Tabs>
            </Box>
          </HStack>
        </Container>
      </GridItem>
    </Grid>
  )
}
export default Promotion
