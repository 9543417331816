import { SortOrder } from '../../../types'

export interface VideoStateInterface {
  isLoading: boolean
  isUploading: boolean
  error: VideoResponseErrorInterface
  videos: VideoInterface[]
  stagingVideo?: VideoInterface[] | null
  selectedVideo?: VideoInterface | null
  uploadedVideo?: VideoInterface | null
  instructors: VideoInstructor[]
  series: VideoSeriesInterface[]
  stagingSeries?: (VideoSeriesInterface & { stepNumber: number }) | null
  updatedSeries?: (VideoSeriesInterface & { coverPhotoUpdated: boolean }) | null
  totalVideos: number
  totalInstructors: number
  tags: string[]
  s3Video: S3VideoInterface | null
}

export interface S3VideoInterface {
  url: string
  fields: string
}

export interface VideoSeriesAndInstructorResponseInterface {
  instructors?: VideoInstructor[]
  series?: VideoSeriesInterface[]
}

export interface VideoInstructor {
  id: string
  fullName: string
}

export interface VideoInstructorAndSeriesInterface {
  id: string
}

export interface InstructorListRequest {
  where?: VideoWhereInputInterface
  orderBy?: VideoOrderByInputInterface[]
  cursor?: VideoWhereUniqueInput
  take?: number
  skip?: number
}
export interface InstructorUpdateInterface {
  data: {
    id?: StringFieldUpdateOperationsInput
    fullName?: StringFieldUpdateOperationsInput
  }
  where?: VideoInstructorWhereUniqueInput
}
export interface InstructorDeleteInterfaceMany {
  where?: VideoInstructorWhereManyInput
}

export interface InstructorDeleteInterface {
  where?: VideoInstructorWhereUniqueInput
}

export interface VideoSeriesDeleteInterfaceMany {
  where?: VideoSeriesWhereManyInput
}

export interface SerieDeleteInterface {
  where?: VideoSeriesWhereUniqueInput
}
export interface VideoInstructorOrderByInputInterface {
  id?: SortOrder
  fullName?: SortOrder
}

export enum QuestionType {
  BOOLEAN = 'BOOLEAN',
  STRING = 'STRING',
  INT = 'INT',
}

export enum QuestionSubType {
  REPETITIONS = 'REPETITIONS',
  ROUNDS = 'ROUNDS',
  TIME = 'TIME',
}

export interface VideoQuestionInterface {
  id?: string
  text: string
  slug?: string
  type: VideoQuestionType
  subType?: VideoQuestionSubType
  defineWatched?: boolean
}

// ============ SERIES =========

export interface VideoSeriesInterface {
  id: string
  name: string
  description: string
  coverImage: string
  basic: boolean
  isComplete?: boolean
  skippable?: boolean
  skipText?: string
}

export interface VideoSeriesCoverImageCreateInterace {
  where: VideoSeriesWhereUniqueInput
  file: File
}

export interface VideoS3LinkInterface {
  where: VideoWhereUniqueInput
  fileExtension: string
}

// ============ END SERIES =======

export interface InstructorSeriesCreateOrUpdateInterface {
  data: {
    name?: string | StringFieldUpdateOperationsInput
    fullName?: string | StringFieldUpdateOperationsInput
    coverImage?: string | StringFieldUpdateOperationsInput
    description?: string | StringFieldUpdateOperationsInput
    basic?: boolean | StringFieldUpdateOperationsInput
    skippable?: boolean | StringFieldUpdateOperationsInput
    skipText?: string | StringFieldUpdateOperationsInput
  }
  where?: VideoSeriesWhereUniqueInput | VideoInstructorWhereUniqueInput
  stepNumber?: number
}

export interface VideoInterface {
  id: string
  title: string
  category: CategoryEnum
  pillarCategory: PillarCategory
  description: string
  difficulty: number
  tags: string[]
  enabled: boolean
  instructorId: string
  seriesId: string
  episode: number
  availableFrom: string
  filename: string
  duration: number
  resolutionWidth: number
  resolutionHeight: number
  sourceUrl: string
  url: string
  fields: string
  thumbnailUrl: string
  selectedThumbnail: string
  workflowStatus: string
  createdAt: string
  updatedAt: string
  VideoInstructor: VideoInstructor
  VideoSeries: VideoSeriesInterface
  VideoQuestion: VideoQuestionInterface[]
}

export enum PillarCategory {
  NUTRITION = 'NUTRITION',
  PHYSICAL = 'PHYSICAL',
  SLEEP = 'SLEEP',
  MENTAL = 'MENTAL',
  LIFESTYLE = 'LIFESTYLE',
}

export enum CategoryEnum {
  WORKOUTS = 'WORKOUTS',
  YOGA = 'YOGA',
  MEDITATION = 'MEDITATION',
  EDUCATION = 'EDUCATION',
  QUICK_DRILLS = 'QUICK_DRILLS',
  BREATHING = 'BREATHING',
  SLEEP = 'SLEEP',
  C9TG = 'C9TG',
  BEYOND_THE_GAME = 'BEYOND_THE_GAME',
  EYE_HEALTH = 'EYE_HEALTH',
  FITGMR_UNIVERSITY = 'FITGMR_UNIVERSITY',
}

export enum Platform {
  WEB = 'Web',
  IOS = 'iOS',
  ANDROID = 'Android',
}

// Types for queries and mutations
export interface VideoCreateRequestInterface {
  data?: VideoCreateInputInterface | null
  file?: any
}

export interface VideoListRequestInterface {
  orderBy: VideoOrderByInputInterface
}

export interface VideoRetrieveRequestInterface {
  where?: VideoWhereUniqueInput
}

export interface VideoDeleteRequestInterface {
  where?: VideoWhereUniqueInput
}

export interface VideoEnableDisableRequestInterface {
  where?: VideoWhereUniqueInput
}

export interface VideoUpdateRequestInterface {
  where?: VideoWhereUniqueInput
  data?: VideoUpdateInputInterface
}

export interface VideoUploadRequestInterface {
  where?: VideoWhereUniqueInput
  file?: any
}

export type VideoCrudInterface =
  | VideoRetrieveRequestInterface
  | VideoUpdateRequestInterface
  | VideoCreateRequestInterface
  | VideoEnableDisableRequestInterface
  | VideoUploadRequestInterface
  | InstructorSeriesCreateOrUpdateInterface
  | VideoDeleteRequestInterface
  | VideoS3LinkInterface

export interface VideoResponseErrorInterface {
  graphQLErrors?: []
  networkError?: number
  message: string
}

export interface VideoOrderByInputInterface {
  id?: SortOrder
  title?: SortOrder
  category?: SortOrder
  pillarCategory?: SortOrder
  description?: SortOrder
  instructor?: SortOrder
  difficulty?: SortOrder
  tags?: SortOrder
  series?: SortOrder
  suggestedDate?: SortOrder
  filename?: SortOrder
  duration?: SortOrder
  resolutionWidth?: SortOrder
  resolutionHeight?: SortOrder
  sourceUrl?: SortOrder
  url?: SortOrder
  thumbnailUrl?: SortOrder
  workflowStatus?: SortOrder
  createdAt?: SortOrder
  updatedAt?: SortOrder
  VideoInstructor?: { fullName: SortOrder }
  VideoSeries?: { name: SortOrder }
}

export interface VideoCreateInputInterface {
  title: string
  category?: CategoryEnum[]
  description?: string
  instructor?: string
  difficulty?: number
  tags?: string[]
  series?: string
  suggestedDate?: string
  season?: number
  episode?: number
  VideoInstructor?: VideoInstructor
  VideoSeries?: VideoSeriesInterface
}

interface StringFieldUpdateOperationsInput {
  set: string | number | string[]
}

interface NullableStringFieldUpdateOperationsInput {
  set: string
}

interface NullableEnumVideoCategoryFieldUpdateOperationsInput {
  set: CategoryEnum
}

interface NullableEnumCategoryFieldUpdateOperationsInput {
  set: PillarCategory
}

interface NullableIntFieldUpdateOperationsInput {
  set?: number
  increment?: number
  decrement?: number
  multiply?: number
  divide?: number
}

interface VideoUpdatetagsInput {
  set: string[]
}

interface VideoInstructorWhereUniqueInput {
  id: string
  fullName?: string
}
export interface VideoInstructorWhereManyInput {
  instructors?: InstructorDeleteInterface[]
}

export interface VideoSeriesWhereManyInput {
  series?: SerieDeleteInterface[]
}

interface VideoSeriesWhereUniqueInput {
  id: string
  name?: string
}

interface VideoInstructorUpdateOneWithoutVideoInput {
  connect?: VideoInstructorWhereUniqueInput
  disconnect?: boolean
}

interface VideoSeriesUpdateOneWithoutVideoInput {
  connect?: VideoSeriesWhereUniqueInput
  disconnect?: boolean
}

interface VideoQuestionUpdateManyWithoutVideoInput {
  create: VideoQuestionCrudInput[]
}

export interface VideoUpdateInputInterface {
  title?: StringFieldUpdateOperationsInput
  category?: NullableEnumVideoCategoryFieldUpdateOperationsInput
  pillarCategory?: NullableEnumCategoryFieldUpdateOperationsInput
  description?: NullableStringFieldUpdateOperationsInput
  difficulty?: NullableIntFieldUpdateOperationsInput
  tags?: VideoUpdatetagsInput
  availableFrom?: NullableStringFieldUpdateOperationsInput
  selectedThumbnail?: StringFieldUpdateOperationsInput
  episode?: NullableIntFieldUpdateOperationsInput
  VideoInstructor?: VideoInstructorUpdateOneWithoutVideoInput
  VideoSeries?: VideoSeriesUpdateOneWithoutVideoInput
  VideoQuestion?: VideoQuestionUpdateManyWithoutVideoInput
  seriesShouldDisconnect?: boolean
  instructorShouldDisconnect?: boolean
  filename?: NullableStringFieldUpdateOperationsInput
}

export interface VideoWhereUniqueInput {
  id?: string
  title?: string
}

export enum QueryMode {
  'default' = 'default',
  'insensitive' = 'insensitive',
}

interface StringFilter {
  contains: string
  mode?: QueryMode
}

interface StringNullableFilter {
  in: string[]
  mode?: QueryMode
}

interface StringNullableListFilter {
  hasSome: string[]
}

interface BoolFilter {
  equals?: Boolean
}

export interface FloatNullableField {
  lt?: number
  lte?: number
  gt?: number
  gte?: number
  not?: FloatNullableField
}

interface EnumVideoCategoryNullableFilter {
  in: CategoryEnum[]
}

export interface VideoWhereInputInterface {
  AND?: Array<VideoWhereInputInterface>
  OR?: Array<VideoWhereInputInterface>
  title?: StringFilter
  tags?: StringNullableListFilter
  category?: EnumVideoCategoryNullableFilter
  instructorId?: StringNullableFilter
  duration?: FloatNullableField
  seriesId?: StringNullableFilter
  enabled?: BoolFilter
}

export interface VideoListRequest {
  where?: VideoWhereInputInterface
  orderBy?: VideoOrderByInputInterface[]
  cursor?: VideoWhereUniqueInput
  take?: number
  skip?: number
}

export interface VideoAggregatedRequestInterface {
  where?: VideoWhereInputInterface
}

// Video Question
export interface VideoQuestionWhereUniqueInput {
  id?: string
  slug?: string
}

export interface VideoCreateNestedManyWithoutVideoQuestionInput {
  connect?: VideoWhereUniqueInput
}

export enum VideoQuestionType {
  BOOLEAN = 'BOOLEAN',
  STRING = 'STRING',
  INT = 'INT',
}

export enum VideoQuestionSubType {
  REPETITIONS = 'REPETITIONS',
  ROUNDS = 'ROUNDS',
  TIME = 'TIME',
}

export interface VideoQuestionCrudInput {
  text?:
    | string
    | {
        set: string
      }
  slug?:
    | string
    | {
        set: string
      }
  type?:
    | VideoQuestionType
    | {
        set: VideoQuestionType
      }
  subType?:
    | VideoQuestionSubType
    | {
        set: VideoQuestionSubType
      }
  defineWatched?:
    | boolean
    | {
        set: boolean
      }
  Video?: VideoCreateNestedManyWithoutVideoQuestionInput
  isCreateOrDeleteQuestion?: boolean
}

export interface VideoQuestionCrudInterface {
  data?: VideoQuestionCrudInput
  where?:
    | VideoQuestionWhereUniqueInput
    | VideoInstructorWhereManyInput
    | VideoSeriesWhereManyInput
}

export const mapVideoInterfaceToUpdateInterface = (
  data: VideoInterface & {
    instructor: string
    series: string
    seriesShouldDisconnect?: boolean
    instructorShouldDisconnect?: boolean
  }
): VideoUpdateInputInterface => {
  const mappedData: VideoUpdateInputInterface = {}

  if (data.title) {
    mappedData.title = { set: data.title }
  }
  if (data.description) {
    mappedData.description = { set: data.description }
  }
  if (data.tags) {
    mappedData.tags = { set: data.tags }
  }
  if (data.category) {
    mappedData.category = { set: data.category }
  }
  if (data.pillarCategory) {
    mappedData.pillarCategory = { set: data.pillarCategory }
  }

  if (data.availableFrom) {
    mappedData.availableFrom = { set: data.availableFrom }
  }

  if (data.selectedThumbnail) {
    mappedData.selectedThumbnail = { set: data.selectedThumbnail }
  }

  if (data.episode) {
    mappedData.episode = { set: data.episode }
  }

  if (data.difficulty) {
    mappedData.difficulty = { set: data.difficulty }
  }

  if (data.instructor) {
    mappedData.VideoInstructor = { connect: { id: data.instructor } }
  } else if (data.instructorShouldDisconnect) {
    mappedData.VideoInstructor = { disconnect: true }
  }

  if (data.series) {
    mappedData.VideoSeries = { connect: { id: data.series } }
  } else if (data.seriesShouldDisconnect) {
    mappedData.VideoSeries = { disconnect: true }
  }

  if (data.VideoQuestion) {
    mappedData.VideoQuestion = { create: data.VideoQuestion }
  }

  return mappedData
}

// Action enum

export enum CONTENT {
  S3LINK_GET_S3_LINK_REQUEST = 's3Link:get:request',
  S3LINK_GET_S3_LINK_FAILED = 's3Link:get:failed',
  S3LINK_GET_S3_LINK_SUCCESS = 's3Link:get:success',
  LOADING = 'content:loading',
  CONTENT_CREATE_REQUEST = 'content:create:request',
  CONTENT_CREATE_SUCCESS = 'content:create:success',
  CONTENT_CREATE_FAILED = 'content:create:failed',
  CONTENT_UPLOAD_REQUEST = 'content:upload:request',
  CONTENT_UPLOAD_SUCCESS = 'content:upload:success',
  CONTENT_UPLOAD_FAILED = 'content:upload:failed',
  CONTENT_UPLOAD_CLEAR_STATE = 'content:upload:clear-state',
  CONTENT_RETRIEVE_REQUEST = 'content:retrieve:request',
  CONTENT_RETRIEVE_SUCCESS = 'content:retrieve:success',
  CONTENT_RETRIEVE_FAILED = 'content:retrieve:failed',
  CONTENT_LIST_REQUEST = 'content:list:request',
  CONTENT_LIST_SUCCESS = 'content:list:success',
  CONTENT_LIST_FAILED = 'content:list:failed',
  CONTENT_UPDATE_REQUEST = 'content:update:request',
  CONTENT_UPDATE_SUCCESS = 'content:update:success',
  CONTENT_UPDATE_FAILED = 'content:update:failed',
  CONTENT_DELETE_REQUEST = 'content:delete:request',
  CONTENT_DELETE_SUCCESS = 'content:delete:success',
  CONTENT_DELETE_FAILED = 'content:delete:failed',
  CONTENT_ENABLE_REQUEST = 'content:enable:request',
  CONTENT_ENABLE_SUCCESS = 'content:enable:success',
  CONTENT_ENABLE_FAILED = 'content:enable:failed',
  CONTENT_DISABLE_REQUEST = 'content:disable:request',
  CONTENT_DISABLE_SUCCESS = 'content:disable:success',
  CONTENT_DISABLE_FAILED = 'content:disable:failed',
  INSTRUCTOR_SERIES_LIST_REQUEST = 'instructor-series:list:request',
  INSTRUCTOR_SERIES_LIST_SUCCESS = 'instructor-series:list:success',
  INSTRUCTOR_SERIES_LIST_FAILED = 'instructor-series:list:failed',
  INSTRUCTOR_CREATE_REQUEST = 'instructor:create:request',
  INSTRUCTOR_CREATE_SUCCESS = 'instructor:create:success',
  INSTRUCTOR_CREATE_FAILED = 'instructor:create:failed',
  INSTRUCTOR_UPDATE_REQUEST = 'instructor:update:request',
  INSTRUCTOR_UPDATE_SUCCESS = 'instructor:update:success',
  INSTRUCTOR_UPDATE_FAILED = 'instructor:update:failed',
  INSTRUCTOR_DELETE_REQUEST = 'instructor:delete:request',
  INSTRUCTOR_DELETE_SUCCESS = 'instructor:delete:success',
  INSTRUCTOR_DELETE_FAILED = 'instructor:delete:failed',
  INSTRUCTOR_LIST_REQUEST = 'instructor:list:request',
  INSTRUCTOR_LIST_SUCCESS = 'instructor:list:success',
  INSTRUCTOR_LIST_FAILED = 'instructor:list:failed',
  SERIES_CREATE_STAGING_REQUEST = 'series:create_staging:request',
  SERIES_CREATE_REQUEST = 'series:create:request',
  SERIES_CREATE_SUCCESS = 'series:create:success',
  SERIES_CREATE_FAILED = 'series:create:failed',
  SERIES_COVER_IMAGE_UPLOAD_REQUEST = 'series-cover-image:upload:request',
  SERIES_COVER_IMAGE_UPLOAD_SUCCESS = 'series-cover-image:upload:success',
  SERIES_COVER_IMAGE_UPLOAD_FAILED = 'series-cover-image:upload:failed',
  SERIES_UPDATE_REQUEST = 'series:update:request',
  SERIES_UPDATE_SUCCESS = 'series:update:success',
  SERIES_UPDATE_FAILED = 'series:update:failed',
  SERIES_DELETE_REQUEST = 'series:delete:request',
  SERIES_DELETE_SUCCESS = 'series:delete:success',
  SERIES_DELETE_FAILED = 'series:delete:failed',
  VIDEO_LIST_METADATA_REQUEST = 'content-meta:get:request',
  VIDEO_LIST_METADATA_SUCCESS = 'content-meta:get:success',
  VIDEO_LIST_METADATA_FAILED = 'content-meta:get:failed',

  VIDEO_TAGS_LIST_REQUEST = 'content-tags:list:request',
  VIDEO_TAGS_LIST_SUCCESS = 'content-tags:list:success',
  VIDEO_TAGS_LIST_FAILED = 'content-tags:list:failed',

  VIDEO_SUBSCRIPTION_UPDATE_REQUEST = 'video-subscription:update:request',
  VIDEO_SUBSCRIPTION_UPDATE_SUCCESS = 'video-subscription:update:success',
  VIDEO_SUBSCRIPTION_UPDATE_FAILED = 'video-subscription:update:failed',

  VIDEO_THUMBNAIL_IMAGE_UPLOAD_REQUEST = 'video-thumbnail-image:upload:request',
  VIDEO_THUMBNAIL_IMAGE_UPLOAD_SUCCESS = 'video-thumbnail-image:upload:success',
  VIDEO_THUMBNAIL_IMAGE_UPLOAD_FAILED = 'video-thumbnail-image:upload:failed',

  VIDEO_QUESTION_CREATE_REQUEST = 'video-question:create:request',
  VIDEO_QUESTION_CREATE_SUCCESS = 'video-question:create:success',
  VIDEO_QUESTION_CREATE_FAILED = 'video-question:create:failed',
  VIDEO_QUESTION_UPDATE_REQUEST = 'video-question:update:request',
  VIDEO_QUESTION_UPDATE_SUCCESS = 'video-question:update:success',
  VIDEO_QUESTION_UPDATE_FAILED = 'video-question:update:failed',
  VIDEO_QUESTION_DELETE_REQUEST = 'video-question:delete:request',
  VIDEO_QUESTION_DELETE_SUCCESS = 'video-question:delete:success',
  VIDEO_QUESTION_DELETE_FAILED = 'video-question:delete:failed',
  INSTRUCTOR_LIST_METADATA_SUCCESS = 'INSTRUCTOR_LIST_METADATA_SUCCESS',
}
