import React, { useMemo, useContext, useEffect } from 'react'
import {
  Avatar,
  Wrap,
  Tooltip,
  Icon as ChackaIcon,
  Button,
} from '@chakra-ui/react'
import { useSelector, useDispatch } from 'react-redux'
import { Link, useHistory } from 'react-router-dom'
import {
  BsChevronRight,
  BsChevronLeft,
  BsTrash,
  BsJournalText,
} from 'react-icons/bs'
import { RiVideoUploadLine } from 'react-icons/ri'
import { useDeleteTeam } from '../../../coaches/hooks/useDeleteTeam'
import { TeamInterface } from '../../../coaches/store/coaches.types'
import { DeleteModal } from '../../../coaches/delete-modal/DeleteModal'
import {
  ITeamProvider,
  TeamContext,
} from '../../../../context/TeamContext/Context'
import {
  ISidebarProvider,
  SidebarContext,
} from '../../../../context/SidebarContext/Context'
import { ReactComponent as Analytics } from '../../../../assets/images/navigation_icons/analytics.svg'
import { ReactComponent as Challenge } from '../../../../assets/images/navigation_icons/challenge.svg'
import { ReactComponent as Users } from '../../../../assets/images/navigation_icons/users.svg'
import { ReactComponent as Video } from '../../../../assets/images/navigation_icons/video.svg'
import { ReactComponent as Portal } from '../../../../assets/images/navigation_icons/portal.svg'
import { ReactComponent as Payments } from '../../../../assets/images/navigation_icons/card.svg'
import { ReactComponent as JournalNotification } from '../../../../assets/images/navigation_icons/journalWithNotification.svg'
import { ReactComponent as Teams } from '../../../../assets/images/navigation_icons/teams.svg'
import { ReactComponent as Performance } from '../../../../assets/images/navigation_icons/performance.svg'
import { ReactComponent as Recommendation } from '../../../../assets/images/navigation_icons/recommendation.svg'

import './Navigation.scss'

import {
  selectIsAdmin,
  selectHasCoachAccess,
  selectIsTeamManager,
  selectIsCoach,
} from '../../../auth/store/auth.selectors'

import { setActionRequest } from '../../../content/store/content.actions'
import { CONTENT } from '../../../content/store/content.types'
import useTeam from '../../../../hooks/useTeam'

type Props = {}

const NavigationOptions = (
  isAdmin: boolean,
  hasCoachAccess: boolean,
  selectedTeam: boolean,
  teamId: string,
  isTeamManager: boolean,
  isCoach: boolean,
  hasNotification: boolean,
  adminVideoUpload: () => void,
  clearSelectedTeam: () => void,
  clearBellNotification: () => void
) => [
  {
    show: isAdmin,
    path: '/home/videos/',
    Icon: Video,
    title: 'Content',
  },
  {
    show: isAdmin,
    path: '/home/video/upload',
    Icon: RiVideoUploadLine,
    isChackaIcon: true,
    title: 'Add a video',
    onClick: () => adminVideoUpload(),
  },
  {
    show: isAdmin,
    path: '/home/video-recommendation/',
    Icon: Recommendation,
    title: 'Recommendation',
  },
  {
    show: isAdmin,
    path: '/home/users/',
    Icon: Users,
    title: 'Users',
  },
  {
    show: isAdmin,
    path: '/home/weekly-challenge/',
    Icon: Challenge,
    title: 'Weekly Challenge',
  },
  {
    show: isAdmin,
    path: '/home/payments/',
    Icon: Payments,
    title: 'Payments',
  },
  {
    show: isAdmin,
    path: '/home/teams-performance/',
    Icon: Teams,
    title: 'Teams Performance',
  },
  {
    show: isAdmin,
    path: '/home/user-performance/',
    Icon: Performance,
    title: 'User Performance',
  },
  {
    show: isAdmin,
    path: '/home/promotion/',
    Icon: Analytics,
    title: 'Promotion',
  },
  {
    show: isCoach,
    path: '/home/coach-portal',
    Icon: Portal,
    title: 'Coach Portal',
  },
  {
    show: isTeamManager,
    path: '/home/manager-portal',
    Icon: Portal,
    title: 'Director Portal',
    onClick: () => clearSelectedTeam(),
  },
  {
    show: hasCoachAccess && selectedTeam,
    path: '/home/my-team/statistics/',
    Icon: Analytics,
    title: 'Analytics',
  },
  {
    show: hasCoachAccess && selectedTeam,
    path: '/home/my-team',
    Icon: Users,
    title: 'My Team',
  },
  {
    show: hasCoachAccess && selectedTeam,
    path: `/home/my-team/journal?teamId=${teamId}`,
    isChackaIcon: !hasNotification,
    Icon: hasNotification ? JournalNotification : BsJournalText,
    title: 'Journal',
    notification: hasNotification,
    onClick: () => clearBellNotification(),
  },
]

export const NewNavigation: React.FC<Props> = () => {
  const { managerTeams, refetchTeams } = useTeam()
  const { setSelectedTeam, selectedTeam } = useContext(
    TeamContext
  ) as ITeamProvider

  useEffect(() => {
    refetchTeams()
  }, [])

  const {
    isNavigationCollapsed,
    isMobileSidebarOpen,
    user,
    setIsNavigationCollapsed,
    closeMobileSidebar,
  } = useContext(SidebarContext) as ISidebarProvider
  const isAdmin: boolean = useSelector(selectIsAdmin)
  const hasCoachAccess: boolean = useSelector(selectHasCoachAccess)
  const isTeamManager: boolean = useSelector(selectIsTeamManager)
  const isCoach: boolean = useSelector(selectIsCoach)
  const route = useHistory()
  const dispatch = useDispatch()

  const {
    title,
    subtitle,
    isDeleteDashboardModalOpen,
    loading,
    onOpenDeleteDashboardModal,
    onCloseDeleteDashboardModal,
    handleDeleteTeam,
  } = useDeleteTeam({ team: selectedTeam as TeamInterface })

  const openDeleteDashboardModal = () => {
    onOpenDeleteDashboardModal()
  }

  const adminVideoUpload = () => {
    dispatch(setActionRequest(CONTENT.CONTENT_UPLOAD_CLEAR_STATE, {}))
    closeMobileSidebar()
  }

  const clearSelectedTeam = () => {
    setSelectedTeam(null)
  }

  const clearBellNotification = () => {
    if (selectedTeam?.id)
      setSelectedTeam({ ...selectedTeam, notificationCount: 0 })
  }

  const selectedPath = useMemo(
    () => route.location.pathname,
    [route.location.pathname]
  )
  const navigationOptions = useMemo(
    () =>
      NavigationOptions(
        isAdmin,
        hasCoachAccess,
        !!selectedTeam,
        selectedTeam?.id || '',
        isTeamManager,
        isCoach,
        !!selectedTeam?.notificationCount,
        adminVideoUpload,
        clearSelectedTeam,
        clearBellNotification
      ),
    [isAdmin, hasCoachAccess, selectedPath, selectedTeam]
  )

  const toogleNavigationState = () => {
    setIsNavigationCollapsed((val) => !val)
  }

  return (
    <>
      <div className="navigation">
        <div
          className={`navigation-wrapper ${
            isNavigationCollapsed && 'collapsed'
          }`}
        >
          <div className="avatar-container">
            <div className="avatar-profile">
              <Avatar name={user?.fullName} src={user?.avatar} size="sm" />
              <div
                className={`profile-info-container ${
                  isNavigationCollapsed && 'collapsed'
                }`}
              >
                <span className="profile-title">{user?.fullName}</span>
                <span className="profile-title profile-role">{user?.role}</span>
              </div>
              <Wrap className="arrow" onClick={toogleNavigationState}>
                {isNavigationCollapsed ? <BsChevronRight /> : <BsChevronLeft />}
              </Wrap>
            </div>
          </div>

          {navigationOptions.map(
            ({
              title,
              path,
              Icon,
              show,
              isChackaIcon,
              onClick,
              notification,
            }) =>
              show && (
                <Link
                  to={path}
                  className={`navigation-option ${
                    selectedPath === path && 'selected'
                  } ${notification && 'notification'}`}
                  // trying to avoid doubled key error
                  key={`${title}-navigation`}
                  onClick={onClick || closeMobileSidebar}
                >
                  <Tooltip
                    hasArrow
                    label={isNavigationCollapsed && title}
                    placement="right"
                  >
                    {/* to show the tooltip correctly */}
                    {isChackaIcon ? (
                      <ChackaIcon>
                        <Icon />
                      </ChackaIcon>
                    ) : (
                      <Icon />
                    )}
                  </Tooltip>
                  <span
                    className={`option-title ${
                      isNavigationCollapsed && 'collapsed'
                    }`}
                  >
                    {title}
                  </span>
                </Link>
              )
          )}

          {isTeamManager && selectedTeam && (
            <div className="delete-section">
              <Button
                className="navigation-option button"
                onClick={openDeleteDashboardModal}
                disabled={managerTeams?.length < 2}
              >
                <Tooltip
                  hasArrow
                  label={isNavigationCollapsed && 'Delete dashboard'}
                  placement="right"
                >
                  <ChackaIcon>
                    <BsTrash />
                  </ChackaIcon>
                </Tooltip>
                <span
                  className={`option-title ${
                    isNavigationCollapsed && 'collapsed'
                  }`}
                >
                  Delete dashboard
                </span>
              </Button>
            </div>
          )}
        </div>

        <DeleteModal
          title={title}
          subtitle={subtitle}
          isSubmiting={loading}
          isOpen={isDeleteDashboardModalOpen}
          onClose={onCloseDeleteDashboardModal}
          onSubmit={handleDeleteTeam}
        />
      </div>
      {isMobileSidebarOpen && (
        <Wrap className="overlay" onClick={closeMobileSidebar} />
      )}
    </>
  )
}
