import React, { PropsWithChildren } from 'react'
import * as Yup from 'yup'
import { Form, Formik, Field, FieldProps } from 'formik'
import {
  Box,
  Button,
  Flex,
  Spacer,
  FormControl,
  FormLabel,
  FormErrorMessage,
  Input,
  InputGroup,
  Text,
  Select,
} from '@chakra-ui/react'
import { Platform } from '../../content/store/content.types'

interface CodeUploadComponentProps extends PropsWithChildren<any> {
  onDataChange?: (data: any) => void
  onSubmit: (data: any) => void
  isSubmitting?: boolean
}

const metadataFormSchema = Yup.object().shape({
  promotionName: Yup.string()
    .max(40, 'promotionName should be less than 40 characters.')
    .required('A promotionName is required.'),
  platform: Yup.string().required('Platform Category is required.'),
})

const PromotionCreateForm: React.FC<CodeUploadComponentProps> = ({
  onSubmit,
  isSubmitting,
}) => (
  <Flex maxWidth="540px" alignItems="center">
    <Box width="100%">
      <Box
        height="100%"
        padding="10"
        background="#363636"
        border="1px solid #555"
        borderRadius="2px"
        minWidth="400px"
        overflowY="scroll"
      >
        <Flex direction="column" height="100%">
          <Text color="white" fontSize="1.15rem" marginBottom="1.15rem">
            Create a new promotion
          </Text>
          <Formik
            validationSchema={metadataFormSchema}
            onSubmit={onSubmit}
            initialValues={{
              promotionName: '',
              platform: Platform.IOS,
            }}
          >
            {() => (
              <Form>
                <Flex direction="column" height="100%">
                  <Field name="promotionName">
                    {({ field, form }: FieldProps) => (
                      <FormControl
                        marginBottom="40px"
                        isDisabled={isSubmitting}
                        isInvalid={Boolean(
                          form.errors.promotionName &&
                            form.touched.promotionName
                        )}
                      >
                        <FormLabel>Promotion Name</FormLabel>
                        <InputGroup>
                          <Input
                            {...field}
                            id="promotionName"
                            placeholder="Promotion Name"
                          />
                        </InputGroup>
                        <FormErrorMessage>
                          {form.errors.promotionName}
                        </FormErrorMessage>
                      </FormControl>
                    )}
                  </Field>
                  <Spacer />
                  <Field name="platform">
                    {({ field, form }: any) => (
                      <FormControl
                        marginBottom="40px"
                        isDisabled={isSubmitting}
                        isInvalid={
                          form.errors.platform && form.touched.platform
                        }
                      >
                        <FormLabel>Platform</FormLabel>
                        <InputGroup>
                          <Select {...field} id="platform">
                            {Object.values(Platform).map((i) => (
                              <option value={i} key={i}>
                                {i.charAt(0).toUpperCase() +
                                  i.substr(1).toLowerCase().replace('_', ' ')}
                              </option>
                            ))}
                          </Select>
                        </InputGroup>
                        <FormErrorMessage>
                          {form.errors.platform}
                        </FormErrorMessage>
                      </FormControl>
                    )}
                  </Field>
                  <Spacer />
                  <Field name="csv">
                    {({ form }: FieldProps) => (
                      <FormControl
                        marginBottom="40px"
                        isDisabled={isSubmitting}
                        isInvalid={Boolean(form.errors.csv && form.touched.csv)}
                      >
                        <FormLabel>Code CSV File</FormLabel>
                        <InputGroup>
                          <Input
                            type="file"
                            id="csv"
                            accept=".csv"
                            onChange={(event) => {
                              form.setFieldValue(
                                'csv',
                                event.currentTarget.files
                                  ? event.currentTarget.files[0]
                                  : null
                              )
                            }}
                          />
                        </InputGroup>
                        <FormErrorMessage>{form.errors.csv}</FormErrorMessage>
                      </FormControl>
                    )}
                  </Field>
                  <Spacer />
                  <Button
                    loadingText="Please wait"
                    type="submit"
                    isFullWidth
                    color="white"
                    colorScheme="fitgmrPurple"
                    isLoading={isSubmitting}
                  >
                    Create Promotion
                  </Button>
                </Flex>
              </Form>
            )}
          </Formik>
        </Flex>
      </Box>
    </Box>
  </Flex>
)

export default PromotionCreateForm
