import React, { Suspense } from 'react'
import { Redirect, Route, Switch } from 'react-router-dom'
import { useSelector } from 'react-redux'
import Container from '../../shared/ui/container'

import { PrivateRouteProps } from '../../../types'
import {
  selectIsTeamManager,
  selectIsAdmin,
  selectIsCoach,
} from '../../auth/store/auth.selectors'

// Components
import { routing as weeklyChallengeRouting } from '../../weekly-challenge/index'
import { routing as usersRouting } from '../../users/index'
import { routing as paymentsRouting } from '../../payments/index'
import { routing as coachRouting } from '../../coaches/index'
import { routing as contentRouting } from '../../content'
import { routing as teamPerformanceRouting } from '../../teams-performance/index'
import { routing as userPerformanceRouting } from '../../user-performance/index'
import { routing as promotionRouting } from '../../promotion/index'
import { routing as recommendationRouting } from '../../video-recommendation/index'

export const routes = [
  weeklyChallengeRouting,
  usersRouting,
  paymentsRouting,
  teamPerformanceRouting,
  userPerformanceRouting,
  recommendationRouting,
  promotionRouting,
  ...contentRouting,
  ...coachRouting,
]

interface SubRoute extends RouteProps {}

interface RouteProps {
  component: React.FC<{ level: number }>
  exact: boolean
  icon?: React.ReactNode
  to: string
  isAdmin?: boolean
  level: number
  children: any[]
}

const AdminRoute: React.FC<PrivateRouteProps> = ({
  children,
  isAdmin,
  ...rest
}) => (
  <Route
    {...rest}
    render={({ location }) =>
      isAdmin ? (
        children
      ) : (
        <Redirect
          to={{
            pathname: '/home/users/',
            state: { from: location },
          }}
        />
      )
    }
  />
)
const renderWithSubRoutes = (subRoute: SubRoute, isAdmin: boolean) => {
  const SubRouteComponent = subRoute.component
  const childrenComponent = (
    <Switch>
      {subRoute.children?.map((route) => renderWithSubRoutes(route, isAdmin))}
    </Switch>
  )

  const subRouteToRender = (
    <Route
      key={subRoute.to}
      path={subRoute.to}
      exact={subRoute.exact}
      render={(props) => (
        <>
          <SubRouteComponent level={subRoute.level} {...props} />
          {childrenComponent}
        </>
      )}
    />
  )

  return subRoute.isAdmin ? (
    <AdminRoute
      key={subRoute.to}
      path={subRoute.to}
      exact={subRoute.exact}
      isAdmin={isAdmin}
    >
      {subRouteToRender}
    </AdminRoute>
  ) : (
    subRouteToRender
  )
}

const renderRoute = (route: RouteProps, isAdmin: boolean) => {
  const Component = route.component
  const routeToRender = (
    <Route
      key={route.to}
      path={route.to}
      exact={route.exact}
      render={(props) => (
        <div style={{ position: 'relative', height: '95%' }}>
          <Component level={route.level} {...props} />
          <Switch>
            {route.children.map((sRoute) =>
              renderWithSubRoutes(sRoute, isAdmin)
            )}
          </Switch>
        </div>
      )}
    />
  )
  return route.isAdmin ? (
    <AdminRoute
      key={route.to}
      path={route.to}
      exact={route.exact}
      isAdmin={isAdmin}
    >
      {routeToRender}
    </AdminRoute>
  ) : (
    routeToRender
  )
}

const Routes: React.FC = () => {
  const isAdmin: boolean = useSelector(selectIsAdmin)
  const hasDirectorAccess: boolean = useSelector(selectIsTeamManager)
  const hasCoachAccess: boolean = useSelector(selectIsCoach)

  return (
    <Suspense fallback={<Container>Loading...</Container>}>
      <Switch>
        {routes.map((route) => renderRoute(route, isAdmin))}
        <Redirect
          to={{
            pathname: isAdmin
              ? '/home/videos/'
              : hasDirectorAccess
              ? '/home/manager-portal'
              : hasCoachAccess
              ? '/home/coach-portal'
              : '/pricing',
          }}
        />
      </Switch>
    </Suspense>
  )
}

export default Routes
