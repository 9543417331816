import React from 'react'
import { AddIcon } from '@chakra-ui/icons'

const Promotion = React.lazy(() => import('../../../pages/Promotion/Promotion'))

export default {
  to: '/home/promotion',
  component: Promotion,
  label: 'Promotion',
  isAdmin: true,
  icon: <AddIcon />,
  exact: true,
  level: 1,
  children: [],
}
